<template>
    <div
        class="rounded-lg customeCard border border-secondaryColor"
        :class="{active: duplicate.active}"
        role="button"
        @click="duplicateChoose"
        style="padding: 1.5rem"
    >
        <b-card-title
            class="text-capitalize"
            v-text="`${resource} ${duplicate.document_num} (duplicate)`"
        />

        <b-card-text>
            <b-row>
                <b-col cols="6">
                    <p class="mb-0 h5" v-text="'Field'" />
                </b-col>
                <b-col cols="6">
                    <p class="mb-0 h5" v-text="'Value'" />
                </b-col>
            </b-row>
            <hr class="divider" />

            <template v-if="field">
                <span
                    class=""
                    v-for="(data, index) in dataDuplicate[language]"
                    :key="index"
                >
                    <div class="d-flex flex-column">
                        <b-row>
                            <template v-if="Array.isArray(data.value)">
                                <template v-if="data.value.length > 0">
                                    <b-col cols="6">
                                        <p
                                            class="mb-0 h5"
                                            :class="generateColor(data.text)"
                                            v-text="
                                                generateLabel(
                                                    data.text,
                                                    language
                                                )
                                            "
                                        />
                                    </b-col>
                                    <b-col cols="6">
                                        <p
                                            class="mb-0 h5"
                                            :class="generateColor(data.text)"
                                            v-text="
                                                generateLabel(
                                                    data.text,
                                                    language
                                                )
                                            "
                                        />
                                    </b-col>

                                    <b-col cols="12">
                                        <hr class="divider" />
                                    </b-col>

                                    <b-col
                                        cols="12"
                                        class="d-flex flex-column"
                                        v-for="(item, mainIndex) in data.value"
                                        :key="mainIndex"
                                    >
                                        <b-row
                                            class="ml-1"
                                            v-for="(
                                                object, value, index
                                            ) in item"
                                            :key="index"
                                        >
                                            <b-col cols="12">
                                                <hr class="divider second2" />
                                            </b-col>
                                            <b-col cols="6">
                                                <p
                                                    :class="
                                                        generateColor(value)
                                                    "
                                                    class="mb-0 h5"
                                                    v-text="
                                                        getField(
                                                            value,
                                                            language,
                                                            data.text
                                                        )
                                                    "
                                                />
                                            </b-col>

                                            <b-col cols="6">
                                                <p
                                                    :class="
                                                        generateColor(value)
                                                    "
                                                    class="mb-0 h5"
                                                    v-text="object"
                                                />
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </template>
                            </template>

                            <template v-else>
                                <b-col cols="6">
                                    <p
                                        :class="generateColor(data.text)"
                                        class="mb-0 h5"
                                        v-text="getField(data.text, language)"
                                    />
                                </b-col>
                                <b-col cols="6">
                                    <p
                                        :class="generateColor(data.text)"
                                        class="mb-0 h5"
                                        v-text="data.value"
                                    />
                                </b-col>
                            </template>
                        </b-row>
                        <hr class="divider" />
                    </div>
                </span>
            </template>
        </b-card-text>
    </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig';
export default {
    name: 'DuplicateDetailsDuplicate',

    props: {
        locale: {
            type: Object,
        },
        resource: {
            type: String,
        },
        duplicate: {
            type: Object,
        },
        matchedFields: {
            type: Array,
        },
        comparedFields: {
            type: Array,
        },
        notComparedFields: {
            type: Array,
        },
    },

    data() {
        return {
            field: '',
            config: useAppConfig(),
            capitalizeResource: '',
            dataDuplicate: {},
        };
    },
    computed: {
        language() {
            if (this.getLang == '') {
                return this.swedishLang ? 'Swedish' : 'English';
            } else return this.getLang == 'sv' ? 'Swedish' : 'English';
        },
        getLang() {
            return this.config.lang.value;
        },
    },

    methods: {
        generateLabel(element, language) {
            return this.field[language][this.capitalizeResource][element].Label;
        },
        duplicateChoose() {
            this.duplicate.active = !this.duplicate.active;

            if (this.duplicate.active) {
                this.$emit('chooseDuplicate', {
                    data: `${this.resource} ${this.duplicate.document_num}`,
                    action: true,
                });
            } else this.$emit('chooseDuplicate', {data: '', action: false});
        },
        generateColor(params) {
            let matchedFields = this.matchedFields.find(
                element => element.text == params
            );
            let comparedFields = this.comparedFields.find(
                element => element.text == params
            );
            let notComparedFields = this.notComparedFields.find(
                element => element.text == params
            );

            if (matchedFields) {
                return matchedFields.colorClass;
            } else if (comparedFields) {
                return comparedFields.colorClass;
            } else if (notComparedFields) {
                return notComparedFields.colorClass;
            }
        },
        getField(element, language, mainElement) {
            if (mainElement) {
                return this.field[language][this.capitalizeResource][
                    mainElement
                ][element];
            } else if (this.field[language][this.capitalizeResource][element]) {
                return this.field[language][this.capitalizeResource][element];
            } else return element;
        },

        generateDuplicateData() {
            let newArray = {
                English: [],
                Swedish: [],
            };
            for (let duplicated in this.duplicate.data) {
                newArray.English.push({
                    text: duplicated,
                    value: this.duplicate.data[duplicated],
                });
                newArray.Swedish.push({
                    text: duplicated,
                    value: this.duplicate.data[duplicated],
                });
            }

            this.dataDuplicate = newArray;
        },
        mapperField() {
            this.$useJwt
                .getFieldMapper({params: {resource: this.resource}})
                .then(res => {
                    const {data} = res.data;
                    this.field = data;

                    if (this.resource) {
                        this.capitalizeResource =
                            this.resource.charAt(0).toUpperCase() +
                            this.resource.slice(1);

                        this.generateDuplicateData();
                    }
                });
        },
    },

    mounted() {
        this.mapperField();
    },
};
</script>

<style scoped>
.active {
    box-shadow: 0px 10px 10px 20px rgba(79, 207, 192, 1);
}

.customeCard {
    transition: all 0.23s ease 0.1s;
}
</style>
