<template>
    <b-card class="rounded-lg">
        <Loading v-if="loadingState" />

        <template v-if="duplicatesDetails">
            <b-row class="mt-2 d-block d-sm-block d-md-none">
                <b-col
                    v-for="(details, index) in detailsLegend"
                    :key="index"
                    cols="12"
                    sm="6"
                    class="d-flex mb-1"
                    style="gap: 0.5rem"
                >
                    <div
                        class="bulletIcon my-auto"
                        :class="details.variantColor"
                    />
                    <p class="mb-0" v-text="$t(details.text)" />
                </b-col>
            </b-row>

            <div class="d-none d-md-flex" style="gap: 1.5rem">
                <div
                    v-for="(details, index) in detailsLegend"
                    :key="index"
                    class="d-flex mb-1"
                    style="gap: 0.5rem"
                >
                    <div
                        class="bulletIcon my-auto"
                        :class="details.variantColor"
                    />
                    <p class="mb-0" v-text="$t(details.text)" />
                </div>
            </div>

            <b-row class="mt-2">
                <DuplicatesDetailsOriginal
                    :locale="locale"
                    :original="original"
                    :resource="resource"
                    :matchedFields="matchedFields"
                    :comparedFields="comparedFields"
                    :notComparedFields="notComparedFields"
                />

                <b-col cols="12" lg="6" v-if="duplicates.length > 0">
                    <b-row>
                        <b-col cols="12">
                            <DuplicateDetailsDuplicate
                                :locale="locale"
                                :resource="resource"
                                :duplicate="
                                    duplicates[pagination.currentPage - 1]
                                "
                                :matchedFields="matchedFields"
                                :comparedFields="comparedFields"
                                :notComparedFields="notComparedFields"
                                @chooseDuplicate="openAction($event)"
                            />
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <b-row align-h="between" class="mt-2">
                <b-col
                    cols="12"
                    sm="6"
                    md="6"
                    class="d-flex align-items-center"
                >
                    <v-select
                        v-if="selectAction"
                        v-model="todoSelected"
                        @input="filterTodo($event)"
                        :clearable="false"
                        :placeholder="$t(locale.todo)"
                        :options="$t(locale.todoOptions)"
                        class="selected d-inline-block"
                    />
                </b-col>

                <b-col
                    cols="12"
                    md="6"
                    sm="6"
                    class="d-flex justify-content-center justify-content-sm-end py-1"
                >
                    <b-pagination
                        v-model="pagination.currentPage"
                        :total-rows="duplicates.length"
                        :per-page="pagination.perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>

            <ModalSingleHandle
                v-if="duplicates.length > 0"
                :uuid="$route.params.id"
                :document_num="chooseDocumentNum"
                :title="singleHandleTitle"
                @refresh="clearTodo"
            />
        </template>
    </b-card>
</template>

<script>
import DuplicatesDetailsOriginal from '@/@core/Duplicates/DuplicatesDetailsOriginal.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useAppConfig from '@core/app-config/useAppConfig';
import vSelect from 'vue-select';
import ModalSingleHandle from '@/@core/Duplicates/ModalSingleHandle.vue';
import DuplicateDetailsDuplicate from '@/@core/Duplicates/DuplicateDetailsDuplicate.vue';

export default {
    name: 'DuplicatesDetails',
    components: {
        vSelect,
        DuplicatesDetailsOriginal,
        ModalSingleHandle,
        DuplicateDetailsDuplicate,
    },
    data() {
        return {
            todoSelected: '',
            config: useAppConfig(),
            original: {},
            resource: '',
            chooseDuplicate: '',
            singleHandleTitle: '',
            duplicates: '',
            loadingState: true,
            duplicatesDetails: false,
            selectAction: false,
            matchedFields: [],
            comparedFields: [],
            notComparedFields: [],
            chooseDocumentNum: null,
            detailsLegend: [
                {
                    variantColor: 'bg-blueLight',
                    text: 'Duplicates.compareAndMatched',
                },
                {
                    variantColor: 'bg-warning',
                    text: 'Duplicates.notMatched',
                },
                {
                    variantColor: 'bg-danger',
                    text: 'Duplicates.compareAndNotMatched',
                },
            ],
            locale: {
                duplicatesDetail: 'Duplicates.duplicatesDetail',
                field: 'Duplicates.field',
                value: 'Duplicates.value',
                todo: 'Duplicates.todo',
                todoOptions: 'Duplicates.todoOptionsDetails',
                accidentmsg: 'Duplicates.accidentMsg',
                awatingDuplicateFetch: 'Duplicates.awatingDuplicateFetch',
            },
            pagination: {
                currentPage: 1,
                perPage: 1,
            },
        };
    },

    computed: {
        msg() {
            if (this.getLang == '') {
                return this.swedishLang ? 'message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'message' : 'eng_message';
        },
        getLang() {
            return this.config.lang.value;
        },
    },

    methods: {
        filterTodo(value) {
            if (value.event !== '') {
                if (value.event == 'notDuplicated') {
                    this.todoSelected = '';
                    this.$swal({
                        text: this.$t(this.locale.accidentmsg, {
                            originalTitle: this.chooseDuplicate,
                        }),
                        icon: 'warning',
                        showCancelButton: true,
                        reverseButtons: true,
                        denyButtonText: this.$t('UsersCard.cancel'),
                        confirmButtonText: this.$t('Duplicates.confirm'),
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-danger mr-1',
                        },
                        buttonsStyling: false,
                    }).then(res => {
                        if (res.value) {
                            this.$useJwt
                                .notDuplicate(
                                    this.$route.params.id,
                                    this.duplicates[
                                        this.pagination.currentPage - 1
                                    ].document_num
                                )
                                .then(res => {
                                    this.todoSelected = '';
                                    this.popupMsg(
                                        this.$t('Message.Success'),
                                        res.data[this.msg],
                                        'CheckIcon',
                                        'success'
                                    );
                                });
                        }
                    });
                } else {
                    this.singleHandleTitle = `${
                        this.resource.charAt(0).toUpperCase() +
                        this.resource.slice(1)
                    } ${
                        this.duplicates[this.pagination.currentPage - 1]
                            .document_num
                    }`;

                    this.chooseDocumentNum =
                        this.duplicates[
                            this.pagination.currentPage - 1
                        ].document_num;

                    this.$bvModal.show('modalSingleHandle');
                }
            }
        },

        clearTodo($event) {
            this.todoSelected = '';
            this.chooseDocumentNum = null;

            if ($event !== 'close') {
                this.getDetailsDuplicates(this.$route.params.id, false);
            }
        },

        openAction(value) {
            this.chooseDuplicate = value.data;
            this.selectAction = value.action;
        },
        generateColorByValue(
            matched_fields,
            compared_fields,
            not_compared_fields
        ) {
            matched_fields.forEach(element => {
                this.matchedFields.push({
                    text: element,
                    colorClass: 'text-blueLight',
                });
            });

            compared_fields.forEach(element => {
                this.comparedFields.push({
                    text: element,
                    colorClass: 'text-danger',
                });
            });

            not_compared_fields.forEach(element => {
                this.notComparedFields.push({
                    text: element,
                    colorClass: 'text-warning',
                });
            });
        },
        getDetailsDuplicates(uuid, firstInstall) {
            this.loadingState = true;
            this.duplicatesDetails = false;

            if (firstInstall) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.$t('Message.wait'),
                        variant: 'warning',
                        text: this.$t('Duplicates.awatingDuplicateFetch'),
                        waiting: true,
                    },
                });
            }

            this.$useJwt.getDuplicateDetails(uuid).then(res => {
                this.duplicates = [];
                this.$toast.clear();
                const {data} = res.data;
                const {Original} = data;
                const {Duplicates} = data;
                const {Resource} = data;
                const {matched_fields} = data; //Green color
                const {compared_fields} = data; //Red Color
                const {not_compared_fields} = data; //Orange Color

                this.original = Original;

                //Generate
                this.generateColorByValue(
                    matched_fields,
                    compared_fields,
                    not_compared_fields
                );

                if (Duplicates.length > 0) {
                    Duplicates.map(element => (element.active = false));

                    this.duplicates = Duplicates;
                }

                this.resource = Resource;

                this.loadingState = false;
                this.duplicatesDetails = true;
            });
        },
    },
    mounted() {
        this.getDetailsDuplicates(this.$route.params.id, true);
    },
};
</script>

<style scoped lang="scss">
.selected {
    width: 100%;
}
.bulletIcon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
