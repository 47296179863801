var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-card',{staticClass:"rounded-lg border border-secondaryColor"},[_c('b-card-title',{staticClass:"text-capitalize",domProps:{"textContent":_vm._s((_vm.resource + " " + (_vm.original.document_num) + " (original)"))}}),_c('b-card-text',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('p',{staticClass:"mb-0 h5",domProps:{"textContent":_vm._s(_vm.$t(_vm.locale.field))}})]),_c('b-col',{attrs:{"cols":"6"}},[_c('p',{staticClass:"mb-0 h5",domProps:{"textContent":_vm._s(_vm.$t(_vm.locale.value))}})])],1),_c('hr',{staticClass:"divider"}),(_vm.field)?_vm._l((_vm.dataOriginal[_vm.language]),function(data,index){return _c('span',{key:index},[_c('div',{staticClass:"d-flex flex-column"},[_c('b-row',[(Array.isArray(data.value))?[(data.value.length > 0)?[_c('b-col',{attrs:{"cols":"6"}},[_c('p',{staticClass:"mb-0 h5",class:_vm.generateColor(data.text),domProps:{"textContent":_vm._s(
                                                _vm.generateLabel(
                                                    data.text,
                                                    _vm.language
                                                )
                                            )}})]),_c('b-col',{attrs:{"cols":"6"}},[_c('p',{staticClass:"mb-0 h5",class:_vm.generateColor(data.text),domProps:{"textContent":_vm._s(
                                                _vm.generateLabel(
                                                    data.text,
                                                    _vm.language
                                                )
                                            )}})]),_c('b-col',{attrs:{"cols":"12"}},[_c('hr',{staticClass:"divider"})]),_vm._l((data.value),function(item,mainIndex){return _c('b-col',{key:mainIndex,staticClass:"d-flex flex-column",attrs:{"cols":"12"}},_vm._l((item),function(object,value,index){return _c('b-row',{key:index,staticClass:"ml-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('hr',{staticClass:"divider second2"})]),_c('b-col',{attrs:{"cols":"6"}},[_c('p',{staticClass:"mb-0 h5",class:_vm.generateColor(value),domProps:{"textContent":_vm._s(
                                                        _vm.getField(
                                                            value,
                                                            _vm.language,
                                                            data.text
                                                        )
                                                    )}})]),_c('b-col',{attrs:{"cols":"6"}},[_c('p',{staticClass:"mb-0 h5",class:_vm.generateColor(value),domProps:{"textContent":_vm._s(object)}})])],1)}),1)})]:_vm._e()]:[_c('b-col',{attrs:{"cols":"6"}},[_c('p',{staticClass:"mb-0 h5",class:_vm.generateColor(data.text),domProps:{"textContent":_vm._s(
                                            _vm.getField(data.text, _vm.language)
                                        )}})]),_c('b-col',{attrs:{"cols":"6"}},[_c('p',{staticClass:"mb-0 h5",class:_vm.generateColor(data.text),domProps:{"textContent":_vm._s(data.value)}})])]],2),_c('hr',{staticClass:"divider"})],1)])}):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }