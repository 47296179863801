<template>
    <b-col cols="12" lg="6">
        <b-card class="rounded-lg border border-secondaryColor">
            <b-card-title
                class="text-capitalize"
                v-text="`${resource} ${original.document_num} (original)`"
            />
            <b-card-text>
                <b-row>
                    <b-col cols="6">
                        <p class="mb-0 h5" v-text="$t(locale.field)" />
                    </b-col>
                    <b-col cols="6">
                        <p class="mb-0 h5" v-text="$t(locale.value)" />
                    </b-col>
                </b-row>
                <hr class="divider" />

                <template v-if="field">
                    <span
                        class=""
                        v-for="(data, index) in dataOriginal[language]"
                        :key="index"
                    >
                        <div class="d-flex flex-column">
                            <b-row>
                                <template v-if="Array.isArray(data.value)">
                                    <template v-if="data.value.length > 0">
                                        <b-col cols="6">
                                            <p
                                                class="mb-0 h5"
                                                :class="
                                                    generateColor(data.text)
                                                "
                                                v-text="
                                                    generateLabel(
                                                        data.text,
                                                        language
                                                    )
                                                "
                                            />
                                        </b-col>
                                        <b-col cols="6">
                                            <p
                                                class="mb-0 h5"
                                                :class="
                                                    generateColor(data.text)
                                                "
                                                v-text="
                                                    generateLabel(
                                                        data.text,
                                                        language
                                                    )
                                                "
                                            />
                                        </b-col>

                                        <b-col cols="12">
                                            <hr class="divider" />
                                        </b-col>

                                        <b-col
                                            cols="12"
                                            class="d-flex flex-column"
                                            v-for="(
                                                item, mainIndex
                                            ) in data.value"
                                            :key="mainIndex"
                                        >
                                            <b-row
                                                class="ml-1"
                                                v-for="(
                                                    object, value, index
                                                ) in item"
                                                :key="index"
                                            >
                                                <b-col cols="12">
                                                    <hr
                                                        class="divider second2"
                                                    />
                                                </b-col>
                                                <b-col cols="6">
                                                    <p
                                                        :class="
                                                            generateColor(value)
                                                        "
                                                        class="mb-0 h5"
                                                        v-text="
                                                            getField(
                                                                value,
                                                                language,
                                                                data.text
                                                            )
                                                        "
                                                    />
                                                </b-col>

                                                <b-col cols="6">
                                                    <p
                                                        :class="
                                                            generateColor(value)
                                                        "
                                                        class="mb-0 h5"
                                                        v-text="object"
                                                    />
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </template>
                                </template>

                                <template v-else>
                                    <b-col cols="6">
                                        <p
                                            :class="generateColor(data.text)"
                                            class="mb-0 h5"
                                            v-text="
                                                getField(data.text, language)
                                            "
                                        />
                                    </b-col>
                                    <b-col cols="6">
                                        <p
                                            :class="generateColor(data.text)"
                                            class="mb-0 h5"
                                            v-text="data.value"
                                        />
                                    </b-col>
                                </template>
                            </b-row>
                            <hr class="divider" />
                        </div>
                    </span>
                </template>
            </b-card-text>
        </b-card>
    </b-col>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig';
export default {
    name: 'DuplicatesDetailsOriginal',

    props: {
        locale: {
            type: Object,
        },
        resource: {
            type: String,
        },
        original: {
            type: Object,
        },
        matchedFields: {
            type: Array,
        },
        comparedFields: {
            type: Array,
        },
        notComparedFields: {
            type: Array,
        },
    },
    data() {
        return {
            field: '',
            config: useAppConfig(),
            capitalizeResource: '',
            dataOriginal: {},
            firstArrayData: '',
        };
    },

    computed: {
        language() {
            if (this.getLang == '') {
                return this.swedishLang ? 'Swedish' : 'English';
            } else return this.getLang == 'sv' ? 'Swedish' : 'English';
        },
        getLang() {
            return this.config.lang.value;
        },
    },

    methods: {
        generateColor(params) {
            let matchedFields = this.matchedFields.find(
                element => element.text == params
            );
            let comparedFields = this.comparedFields.find(
                element => element.text == params
            );
            let notComparedFields = this.notComparedFields.find(
                element => element.text == params
            );

            if (matchedFields) {
                return matchedFields.colorClass;
            } else if (comparedFields) {
                return comparedFields.colorClass;
            } else if (notComparedFields) {
                return notComparedFields.colorClass;
            }
        },
        generateLabel(element, language) {
            return this.field[language][this.capitalizeResource][element].Label;
        },
        getField(element, language, mainElement) {
            if (mainElement) {
                return this.field[language][this.capitalizeResource][
                    mainElement
                ][element];
            } else if (this.field[language][this.capitalizeResource][element]) {
                return this.field[language][this.capitalizeResource][element];
            } else return element;
        },

        generateOriginalData() {
            let newArray = {
                English: [],
                Swedish: [],
            };
            for (let duplicateOriginal in this.original.data) {
                newArray.English.push({
                    text: duplicateOriginal,
                    value: this.original.data[duplicateOriginal],
                });
                newArray.Swedish.push({
                    text: duplicateOriginal,
                    value: this.original.data[duplicateOriginal],
                });
            }

            this.dataOriginal = newArray;
        },
        mapperField() {
            this.$useJwt
                .getFieldMapper({params: {resource: this.resource}})
                .then(res => {
                    const {data} = res.data;
                    this.field = data;

                    if (this.resource) {
                        this.capitalizeResource =
                            this.resource.charAt(0).toUpperCase() +
                            this.resource.slice(1);

                        this.generateOriginalData();
                    }
                });
        },
    },

    mounted() {
        this.mapperField();
    },
};
</script>

<style></style>
