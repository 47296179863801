<template>
    <b-modal
        id="modalSingleHandle"
        ok-variant="primary"
        ok-only
        :ok-title="$t(locale.confirm)"
        @close="closeModal"
        @cancel="closeModal"
        @hide="closeModal"
        cancel-variant="outline-secondary"
        modal-class="modal-primary"
        centered
        clickToClose
    >
        <b-card-text
            class="d-flex flex-column justify-content-center"
            style="gap: 1.5rem"
        >
            <p
                class="mb-0 font-weight-normal pb-0 text-center h4"
                v-text="$t(locale.singleHandleTitle, {title: title})"
            />

            <div class="d-flex text-center flex-column px-4">
                <p class="mb-0 pb-0 h4" v-text="$t(locale.handleConfirm)" />
            </div>
        </b-card-text>

        <template #modal-footer>
            <b-button class="" @click="updateSingleHandle" variant="primary">
                <span v-if="loading">
                    <b-spinner small type="grow" />
                    {{ $t(locale.loading) }}...
                </span>

                <span v-else v-text="$t(locale.confirm)" />
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import {duplicatesMixins} from '@/mixins/duplicatesMixins';
import useAppConfig from '@core/app-config/useAppConfig';

export default {
    mixins: [duplicatesMixins],
    name: 'ModalsingleHandle',
    props: {
        uuid: {
            type: String,
        },
        document_num: {
            type: Number,
        },
        title: {
            type: String,
        },
    },
    data() {
        return {
            loading: false,
            config: useAppConfig(),
            locale: {
                handleConfirm: 'Duplicates.handleGroupConfirm',
                confirm: 'Duplicates.confirm',
                singleHandleTitle: 'Duplicates.singleHandleTitle',
                loading: 'Message.loading',
            },
        };
    },
    computed: {
        msg() {
            if (this.getLang == '') {
                return this.swedishLang ? 'message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'message' : 'eng_message';
        },
        getLang() {
            return this.config.lang.value;
        },
    },

    methods: {
        updateSingleHandle(event) {
            event.preventDefault();
            this.loading = true;

            this.$useJwt
                .handleSingle(this.uuid, this.document_num)
                .then(res => {
                    this.popupMsg(
                        this.$t('Message.Success'),
                        res.data[this.msg],
                        'CheckIcon',
                        'success'
                    );

                    this.$emit('refresh');

                    this.$bvModal.hide('modalSingleHandle');
                })
                .catch(err => {
                    if (err.response) {
                        this.popupMsg(
                            this.$t('Message.Failed'),
                            err.response.data[this.msg],
                            'AlertTriangleIcon',
                            'danger'
                        );

                        this.$emit('refresh');

                        this.$bvModal.hide('modalSingleHandle');
                    }
                })
                .finally(() => (this.loading = false));
        },
        closeModal() {
            this.$emit('refresh', 'close');
        },
    },
};
</script>

<style></style>
